<template>
  <div class="home">
    <h1>Jumpin POC</h1>
    <div class="container">
      <button class="main-btn" @click="openCammera">{{ resolveText }}</button>
      <div v-show="isCameraOpen && isLoading" class="camera-loading">
        <ul class="loader-circle">
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>

      <div
        v-if="isCameraOpen"
        v-show="!isLoading"
        class="camera-box"
        :class="{ flash: isShotPhoto }"
      >
        <div class="camera-shutter" :class="{ flash: isShotPhoto }"></div>

        <video
          v-show="!isPhotoTaken"
          ref="camera"
          :width="450"
          :height="337.5"
          autoplay
        ></video>

        <canvas
          v-show="isPhotoTaken"
          id="photoTaken"
          ref="canvas"
          :width="450"
          :height="337.5"
        ></canvas>
      </div>

      <div v-if="isCameraOpen && !isLoading" class="camera-shoot">
        <button type="button" class="button" @click="takePhoto">
          <img
            src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png"
          />
        </button>
      </div>

      <div v-if="isPhotoTaken && isCameraOpen" class="camera-download">
        <a
          id="downloadPhoto"
          download="my-photo.jpg"
          class="button"
          role="button"
          @click="downloadImage"
        >
          Download
        </a>
      </div>

      <button
        :disabled="loadingPosition"
        class="main-btn"
        v-if="checkGeoLocation"
        @click="getUserLocation"
      >
        Localize me
      </button>

      <div class="position-container" v-if="latitude && longitude">
        <label>Latitude: {{ latitude }}</label>
        <label>Longitude: {{ longitude }}</label>
      </div>
      <div class="map-container" v-if="latitude && longitude">
        <img :src="src" />
      </div>

      <button class="main-btn" @click="showNotification">
        Show Desktop Notification
      </button>

      <button class="main-btn" @click="subscribeToPushNotification">
        Subscribe to push notifications
      </button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
/* eslint-disable no-useless-escape */

export default {
  name: "HomeView",
  data() {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      link: "#",
      loadingPosition: false,
      latitude: 0,
      longitude: 0,
    };
  },
  computed: {
    checkGeoLocation() {
      return navigator.geolocation;
    },
    resolveText() {
      return this.isCameraOpen ? "Close Cammera" : "Open Cammera";
    },
  },
  methods: {
    getUserLocation() {
      this.loadingPosition = true;
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.src =
            "https://maps.googleapis.com/maps/api/staticmap?center=" +
            this.latitude +
            "," +
            this.longitude +
            "&zoom=16&size=550x400&markers=icon:https://i.ibb.co/PZyJWdP/marker.png|" +
            this.latitude +
            "," +
            this.longitude +
            "&key=AIzaSyDTvuisfH7eyVRBBYrZYTdaGhlOVb4LP9k";
        },
        (error) => {
          console.log(error);
          alert("Couldnt get position");
        },
        {
          timeout: 8000,
        }
      );
    },
    openCammera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },
    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true,
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          alert("May the browser didn't support or there is some errors.");
        });
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    },

    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      const context = this.$refs.canvas.getContext("2d");
      context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
    },

    downloadImage() {
      const download = document.getElementById("downloadPhoto");
      const canvas = document
        .getElementById("photoTaken")
        .toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
      download.setAttribute("href", canvas);
    },
    showNotification() {
      console.log("here");
      Notification.requestPermission((result) => {
        if (result === "granted") {
          navigator.serviceWorker.ready.then((swreg) => {
            const options = {
              body: "Test Message Body",
            };
            swreg.showNotification("Test notification", options);
          });
        }
      });
    },
    subscribeToPushNotification() {
      let reg;
      navigator.serviceWorker.ready
        .then((swreg) => {
          reg = swreg;
          return swreg.pushManager.getSubscription();
        })
        .then((sub) => {
          if (!sub) {
            const vapidPublikKey =
              "BKHBq1arDQltmI_jUrvNiyPBeFxIu6Nx0IqSsbaUEgnDGErjXlnzPRZCGFHJnQ3ZquMZuOR4H2TbYKITL7MEKQ8";
            const convertedVapidKey =
              this.urlBase64ToIint8Array(vapidPublikKey);
            return reg.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: convertedVapidKey,
            });
          } else {
            // existing
          }
        })
        .then((newSub) => {
          return fetch(
            "https://vuejs-http-bb088.firebaseio.com/subscriptions.json",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              body: JSON.stringify(newSub),
            }
          );
        })
        .then((res) => {
          console.log(res);
          navigator.serviceWorker.ready.then((swreg) => {
            const options = {
              body: "Subscribed",
            };
            swreg.showNotification("Subscribed", options);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    urlBase64ToIint8Array(base64String) {
      var padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      var base64 = (base64String + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");

      var rawData = window.atob(base64);
      var outputArray = new Uint8Array(rawData.length);

      for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },
  },
};
</script>
<style lang="scss">
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 1rem;
}
.position-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.main-btn {
  width: 20rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: orange;
  color: white;
  border-radius: 10px;
  border: 0;
}
body {
  display: flex;
  justify-content: center;
}

.web-camera-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 500px;

  .camera-button {
    margin-bottom: 2rem;
  }

  .camera-box {
    .camera-shutter {
      opacity: 0;
      width: 450px;
      height: 337.5px;
      background-color: #fff;
      position: absolute;

      &.flash {
        opacity: 1;
      }
    }
  }

  .camera-shoot {
    margin: 1rem 0;

    button {
      height: 60px;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;

      img {
        height: 35px;
        object-fit: cover;
      }
    }
  }

  .camera-loading {
    overflow: hidden;
    height: 100%;
    position: absolute;
    width: 100%;
    min-height: 150px;
    margin: 3rem 0 0 -1.2rem;

    ul {
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 999999;
      margin: 0;
    }

    .loader-circle {
      display: block;
      height: 14px;
      margin: 0 auto;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      transform: translateX(-50%);
      position: absolute;
      width: 100%;
      padding: 0;

      li {
        display: block;
        float: left;
        width: 10px;
        height: 10px;
        line-height: 10px;
        padding: 0;
        position: relative;
        margin: 0 0 0 4px;
        background: #999;
        animation: preload 1s infinite;
        top: -50%;
        border-radius: 100%;

        &:nth-child(2) {
          animation-delay: 0.2s;
        }

        &:nth-child(3) {
          animation-delay: 0.4s;
        }
      }
    }
  }

  @keyframes preload {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
